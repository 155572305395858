<template>
  <AccordionRows
    :entities="entities"
    :fields="fieldRows"
    :cols="12"
    :isPending="currentIsPending"
  />
</template>
<script>
import AccordionRows, { entityRow, entityRowField } from "./AccordionRows.vue";
import { computed } from "vue-demi";
import { usePartBom } from "../../store/partBom.pinia";
import { useGetHelper } from "../../utils/feathers-pinia/use-get-helpers";

export default {
  name: "PartGroupAccordion",
  props: {
    node: {
      default: null,
    },
    paneInfo: {
      default: null,
    },
    isPending: {
      default: () => ({}),
    },
  },
  components: {
    AccordionRows,
  },
  setup(props) {
    console.warn("PartGroupAccordion.vue");
    const partBomStore = usePartBom();

    const partBomId = computed(() => props?.node?.part?.id ?? null);
    const { item: partBomResult, isPending: isPendingBom } = useGetHelper(
      partBomId,
      partBomStore
    );

    // filter partbomresult for duplicate child_part_ids
    const partBomResultNoDuplicates = computed(() => {
      return partBomResult?.value?.data.filter((partBom) => partBom.part);
    });

    const allBoms = computed(() => partBomResultNoDuplicates?.value ?? []);

    const currentIsPending = computed(() => {
      return {
        ...props.isPending,
        bom: isPendingBom.value,
        arrayItem: isPendingBom.value,
      };
    });

    const entities = computed(() => {
      return {
        bom: allBoms?.value,
      };
    });

    const fieldRows = computed(() => {
      return [
        entityRow(
          "Part Group BOM",
          [
            entityRowField("arrayItem", "part.part_number", {
              label: "Part Number",
              colAttributes: {
                cols: 12,
                sm: 3,
                class: "px-2",
              },
            }),
            entityRowField("arrayItem", "part.description", {
              label: "Description",
              colAttributes: {
                cols: 12,
                sm: 7,
                class: "px-2",
              },
            }),
            entityRowField("arrayItem", "quantity", {
              label: "Quantity",
              colAttributes: {
                cols: 12,
                sm: 2,
                class: "px-2",
              },
            }),
          ],
          {
            rowType: "tree",
            arrayPath: "bom",
          }
        ),
      ];
    });

    return {
      entities,
      fieldRows,
      currentIsPending,
    };
  },
};
</script>
